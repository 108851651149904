<template>
  <div class="dashboard">
    <div class="dashboard__container pb-5 pt-5">
      <div class="dashboard__container--header pa-0 flex-row align-start justify-space-between">
        <h1>Human Resources Hub</h1>
        <span class="flex align-center flex-wrap">
         <!--  <router-link :to="{name: 'openTickets'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isOpen, 'btn__outlined': !isOpen }">Open</button>
          </router-link>
          <router-link :to="{name: 'progressTickets'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isProgress, 'btn__outlined': !isProgress }">In Progress</button>
          </router-link>
          <router-link :to="{name: 'closedTickets'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isClosed, 'btn__outlined': !isClosed }">Closed</button>
          </router-link> -->
<!--           <router-link :to="{name: 'orderWatchlist'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isOrderWatchlist, 'btn__outlined': !isOrderWatchlist }">Orders</button>
          </router-link>
          <router-link :to="{name: 'groupWatchlist'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isGroupWatchlist, 'btn__outlined': !isGroupWatchlist }">Groups</button>
          </router-link> -->
          <!-- <button class="btn btn__outlined btn__small" @click="goBack()"><i class="fas fa-arrow-left"></i></button> -->
        </span>
        
      </div>
      <div class="dashboard__container--body pt-3 pb-5 mb-5">
        <div class="dashboard__container--body--col">
          <div class="card" style="box-shadow: none; border: 2px solid #ddd;">
            <h4 class="mb-3">My Documents</h4>
            <div v-if="userProfile.letters && userProfile.letters.length >= 1" class="mb-2">
              <span class="strong">Offer Letter:</span> <a :href="userProfile.letters[0].url" target="_blank">Uploaded {{formatDate(userProfile.letters[0].date)}}</a>
            </div>
            
            <div v-if="userProfile.employeeFiles && userProfile.employeeFiles.length >= 1" class="mb-2">
              <span v-for="(item, index) in userProfile.employeeFiles">
                <span v-if="item.fileName === 'Jump Staffing H&S Manual - June 2023.pdf'">
                  <span class="strong">Health & Safety Manual:</span> 
                  <a :href="item.downloadUrl" target="_blank"> Uploaded {{item.publishedAt | moment("MM/DD/YYYY")}}</a>
                </span>
              </span>
            </div>

            <div v-if="userProfile.hsmanuals && userProfile.hsmanuals.length >= 1" class="mb-2">
              <span class="strong">Health & Safety Manual:</span> <a :href="userProfile.hsmanuals[0].url" target="_blank">Uploaded {{formatDate(userProfile.hsmanuals[0].date)}}</a>
            </div>
            <div v-if="userProfile.ptos && userProfile.ptos.length >= 1" class="mb-2">
              <span class="strong">PTO Policy:</span> <a :href="userProfile.ptos[0].url" target="_blank">Uploaded {{formatDate(userProfile.ptos[0].date)}}</a>
            </div>
            <div v-if="userProfile.heatIllness && userProfile.heatIllness.length >= 1" class="mb-2">
              <span class="strong">Heat Illness Training:</span> <a :href="userProfile.heatIllness[0].url" target="_blank">Uploaded {{formatDate(userProfile.heatIllness[0].date)}}</a>
            </div>
            <div v-if="userProfile.covidTraining && userProfile.covidTraining.length >= 1" class="mb-2">
              <span class="strong">COVID Training:</span> <a :href="userProfile.covidTraining[0].url" target="_blank">Uploaded {{formatDate(userProfile.covidTraining[0].date)}}</a>
            </div>
          </div>

          <div class="card" style="box-shadow: none; border: 2px solid #ddd;">
            <h4 class="mb-3">My Benefits</h4>
            <div><span class="strong">PTO Balance:</span> {{userProfile.ptoBalance || '0'}}</div>
          </div>
        </div>
        <div class="dashboard__container--body--col">
          <div class="card" style="box-shadow: none; border: 2px solid #ddd;">
            <span class="flex justify-space-between">
              <h3 class="mb-2">Get Support</h3>
            </span>
            Please email hr@jumpstaffing.com if you need additional support.
          </div>
        </div>
      </div>
      <!-- <router-view :key="$route.params.id" /> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import * as moment from 'moment'

export default {
  name: 'HRHome',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    //FjJyw1p7YBd5O0sMoQ3tmkgW9nD3
    // isOpen() {
    //   return this.$route.name == 'openTickets';
    // },
    // isProgress() {
    //   return this.$route.name == 'progressTickets';
    // },
    // isClosed() {
    //   return this.$route.name == 'closedTickets';
    // },
  },
  // created () {
  //   this.$store.dispatch("getSupportRequests")  
  // },
  methods: {
    goBack() {
      router.go(-1)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('MM/DD/YYYY')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
  }
}
</script>